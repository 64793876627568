.recording_canvas {
  width: 100%;
  flex: 1;
}

.recording_div {
  display: flex;
  flex:1;
  min-height: 0;
  width: 100%;
  overscroll-behavior: none;
}

.error_div {
  width: 100%;
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.loading_div {
  width: 100%;
  flex:1;
}

select {
    border-radius: 10px;
    border: solid black 1px;
    background-color: transparent;
}

input {
  border-radius: 10px;
  border: solid black 1px;
  background-color: transparent;
}

input:hover {
  background-color: ghostwhite;
}

div#ui_controls {
    position: absolute;
    float: left;
    display: flex;
    flex-direction: column;
    height: min-content;
    left: 100px;
    top: 100px;
}

#ui_controls > * {
    margin-bottom: 1em;
}

.ui_button {
    height: 40px;
    max-width: 300px;
    width: 15vw;
}

.colormap_selector{
    height: 40px;
    max-width: 300px;
    width: 15vw;
}

.artifact_list {
    font-family: monospace;
    list-style: none;
    counter-reset: num;
    border: 1px solid black;
    border-radius: 10px;
    text-align: center;
    padding: 0.2em 0.1em;
    background-color: #eee;
}

.artifact_list li {
    counter-increment: num;
    padding: 0.25em 0.25em;
}

.artifact_list li::before {
    content: "Artifact " counter(num);
    padding: 0 0.5em;
    margin-right: 0.5em;
    background-color: #50cbdc;
    border-radius: 8px;
}

.artifact_list li:hover::before {
    background-color: #4fe34f;
}

#artifact_message {
    overflow: wrap;
    max-width: 300px;
    text-align: center;
    padding-top: 1em;
    margin: auto;
}

select {
  border-radius: 10px;
  border: solid black 1px;
  background-color: transparent;
}

.hidden_canvas {
  display: none;
  height:960px;
  width:480px;
}
