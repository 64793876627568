.select_exam_window{
    width: 100%;
    display: flex;
    flex:1;
    overscroll-behavior: none;
    min-height: 0;

}

.select_component{
    width: 33.3%;
    border: 1px solid black;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 0;
    flex:1;

}

.select_title {
    height:50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-transform:capitalize;
    background-color: #C4C4C4;
    
}

.option {
    width: 100%;
    height: 40px;
    padding: 3px 0px 3px 10px;
    display: flex;
    align-items: center;

}

.option:nth-child(odd){
    background-color: #D8D8D8;
}

.option:hover {
    background-color: azure;
    cursor: pointer;
}

.selected {
    color: white;
    background-color: #0070ce !important;
}

/* small screen support */
@media only screen and (max-width: 600px) {
    .component_selected {
        min-width: 60px !important;
        flex:0;
    }
}
