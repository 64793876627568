body {
  margin: 0;
  font-family: 'Helvetica Neue',   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0;
}

.container {
  width: 100%;
  
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  display: flex;
  flex-direction: column;
  align-items: center;
}

input{box-sizing:border-box} 

.exam_load_error_div {
  width: 100%;
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: red;

}

