.navbar {
    width: 100%;
    color: rgb(0, 0, 0);
    height:50px;
}

.navbar_container {
    height: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.navbar_button {
    background-color: #4F4F4F;
    color: white;
    height: 100%;
    min-width: 100px;
    width: 33.3%;
    max-width: 200px;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;

}

.navbar_button:hover{
    cursor: pointer;
    background-color: #6b6b6b;
}

.navbar_location {
    padding: 20px 20px;
}