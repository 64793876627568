.login_div {
    margin: 100px 50px;
    padding:50px;
    max-width: 1000px;
    background-color: #0070ce;
    color: white;
    border-radius: 20px;
}
  
.login_input {
    width: 100%;
    margin-bottom: 20px;
    font-size: 1.2em;
    margin-top: 5px;
    background-color: white;
  }

.login_submit {
    width: 100%;
    margin-top: 10px;
    height: 40px;
}

.login_message {
    padding: 10px;    
}